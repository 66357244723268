import React, {useState, useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import {NavLink} from "react-router-dom";
import {Dropdown} from "antd";
import {MenuOutlined, BellOutlined, SettingOutlined} from "@ant-design/icons";
import Notification from "./Notificatiion/notification.";
import {useDispatch, useSelector} from "react-redux";
import {USER_TYPE_CREW} from "../constants";
import {listNotifications} from "../state/notifications/action";
import {checkObjectEmptyOrNull} from "../utils/help";
import "./Header.scss";

export default function Header() {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const {user} = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  useEffect(() => {
    const getListNotificationsOfUser = async () => {
      await dispatch(listNotifications(0))
    }
    getListNotificationsOfUser()
  }, [dispatch])

  const handleVisibleChange = flag => {
    setVisible(flag)
  };

  const closeDropdown = () => {
    setVisible(false)
  }

  return (
    <header className={`header ${user?.isVerified === 0 && 'd-none'}`}>
      <div className="d-flex align-items-center">
        <div className="header-logo cursor-pointer">
          <NavLink to='/'><img src="/images/logo.png" alt=""/></NavLink>
        </div>
      </div>
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
      >
        <nav className="Nav">
          <NavLink exact to="/" activeClassName="selected">Home</NavLink>
          {checkObjectEmptyOrNull(user) ?
            <React.Fragment>
              <NavLink to="/search-crew" activeClassName="selected">Search Crew</NavLink>
              <NavLink to="/search-project" activeClassName="selected">Search Project</NavLink>
              <NavLink to="/login" activeClassName="selected">Login</NavLink>
              <NavLink to="/login" activeClassName="selected">Sign up</NavLink>
            </React.Fragment>
            :
            <React.Fragment>
              {user && user.UserType !== USER_TYPE_CREW &&
                <NavLink to="/search-crew" activeClassName="selected">Search Crew</NavLink>
              }
              <NavLink to="/appointment" activeClassName="selected">Appointment</NavLink>
              {user && user.UserType !== USER_TYPE_CREW &&
                <NavLink to="/booking" activeClassName="selected">Booking</NavLink>
              }
              {user && user.UserType === USER_TYPE_CREW &&
                <>
                  <NavLink to="/search-project" activeClassName="selected">Search Project</NavLink>
                  <NavLink to="/earning" activeClassName="selected">Earning</NavLink>               </>

              }
              <NavLink to="/history" activeClassName="selected">History</NavLink>
              <NavLink to="/connection" activeClassName="selected">Connection</NavLink>
              <NavLink to="/profile" activeClassName="selected">Profile</NavLink>
              <NavLink to="/about-us" activeClassName="selected">About Us</NavLink>
              <NavLink className='link-download-app' to="/app" activeClassName="selected">Download app</NavLink>
              <Dropdown placement="bottomLeft"
                        visible={visible}
                        onVisibleChange={handleVisibleChange}
                        trigger={['click']}
                        overlay={<Notification closeDropdown={closeDropdown}/>}
              >
                <BellOutlined className="cursor-pointer"/>
              </Dropdown>
              <NavLink to="/setting" className='link-icon' activeClassName="selected"><SettingOutlined className='icon-link-setting'/></NavLink>

            </React.Fragment>

          }

        </nav>
      </CSSTransition>
      <MenuOutlined onClick={toggleNav} className="Burger text-white mt-1"/>

    </header>
  );
}
