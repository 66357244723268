import React, {useMemo, useRef} from 'react';
import Slider from "react-slick";
import {LeftOutlined, MailFilled, PhoneFilled, RightOutlined} from "@ant-design/icons";
import {Space} from "antd";
import Button from "../../components/button";
import './style.scss';
import {useHistory} from "react-router-dom";

const videoMp4 = 'https://www.w3schools.com/html/mov_bbb.mp4';
const videoOgg = 'https://www.w3schools.com/html/mov_bbb.ogg';
const settingsSlick = {
  dots: false,
  infinite: true,
  autoplay: false,
  speed: 1000,
  autoplaySpeed: 3000,
  cssEase: "linear",
  slidesToShow: 1,
  slidesToScroll: 1,
  // initialSlide: initialSlide,
  arrows: false,
};
const images = [
  {
    source: "https://i.ibb.co/5kmVwDw/9lZSosto.png",
    title: "Little Venice in Colmar"
  },
  {
    source: "https://i.ibb.co/5kmVwDw/9lZSosto.png",
    title: "Back View of a Man Standing on a Rock near the Waterfalls"
  },
  {
    source: "https://i.ibb.co/5kmVwDw/9lZSosto.png",
    title: "Machu Pichu, Peru"
  },
  {
    source: "https://i.ibb.co/5kmVwDw/9lZSosto.png",
    title: "Machu Pichu, Peru"
  },
  {
    source: "https://i.ibb.co/5kmVwDw/9lZSosto.png",
    title: "Machu Pichu, Peru"
  },
];

function AboutUsPage() {
  const sliderRef = useRef();
  const history = useHistory();
  const options = useMemo(() => {
    return images.map((image, index) => (
      <div className="position-relative">
        <div className="slogan position-absolute">
          <h3 className="slogan-title">ANY CREW, ANY TIME, ANYWHERE</h3>
          <div className="button-find mb-4 mt-4">
            <Button onClick={() => history.push("/search-crew")}>
              <img src="/images/crew.png" width="30" alt=""/> Find Crew Now
            </Button>
            <Button onClick={() => history.push("/search-project")}>
              <img src="/images/project.png" width="30" alt=""/>Find A Project Near You
            </Button>
          </div>
          <span className="slogan-content">Crews Now provides listings for video, film, and production staff with easy search features for local market locations, positions, & owned equipment. For specific video & film crew members <strong>CREWSNOW</strong> provides Elite status based on vetting accuracy of the listing, equipment quality, and successful shoot bookings.</span>
        </div>
        <img
          src={image.source}
          alt={image.title}
          loading="lazy"
          className="w-100"
          draggable={false}
        />
      </div>
    ))
  }, []);
  return (
    <div className='home-page'>
      <div className='banner-slide'>
        <Slider {...settingsSlick} initialSlide={2} className='list-services-config'>
          {options}
        </Slider>
      </div>
      <div className="information">
        <div className="top-info">
          <div className="info-top">
            <img src="/images/logo.png" width="320" alt=""/>
            <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-4">
              {/*<p className="name-service"><PhoneFilled/>987-654-3210</p>*/}
              <p className="location"><MailFilled/>support@crewsnow.com</p>
            </div>
          </div>
          <div className="info-bottom">
            <Space>
              <img src="/images/image1.png" width="50" alt=""/>
              <Space direction="vertical">
                <span className="sub-title">GEARS</span>
                <span className="sub-value">CAMERA, LIGHTING, SOUND, LIVE STREAM AND MORE</span>
              </Space>
            </Space>
            <Space>
              <img src="/images/image2.png" width="50" alt=""/>
              <Space direction="vertical">
                <span className="sub-title">TYPES OF SHOOTS</span>
                <span className="sub-value">LIVE EVENT, CORPORATE, FILM, VIDEO, COMMERCIAL AND MORE</span>
              </Space>
            </Space>
          </div>
        </div>
        <div className="bottom-info">
          <div className="mt-4 d-flex bottom-wrapper">
            <div className="w-50 bottom-left"><img src="/images/device.png" className="w-100" alt=""/></div>
            <div className="bottom-right w-50 d-flex flex-column gap-4">
              <span className="sub-title">
                THE ONE STOP SHOP SHOOT CREWING RESOURCE
              </span>
              <span className="sub-value">
                The power is in your hands from booking to final billing. One source that allows everyone to work smarter, not harder, with an all encompassing solution for booking, crewing, managing and invoicing your gigs.
              </span>
              <Button onClick={() => history.push("/search-project")}>
                Find A Local Project
              </Button>
              <span className="sub-title">
                WE CREW FOR ALL POSITIONS
              </span>
              <span className="sub-value">
                We are the best crewing app for the production world. We help you find the best local crew members for your project any time, any place, any crew.
              </span>
              <Button onClick={() => history.push("/search-crew")}>
                Hire Your Crews Now
              </Button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default AboutUsPage;
