import React, {useEffect, useState, useMemo} from 'react';
import {useHistory} from "react-router-dom";
import {getListServices} from "../../actions/commonActions";
import {DollarCircleFilled, UserOutlined, EnvironmentFilled, LoadingOutlined, ProfileFilled} from "@ant-design/icons/lib/icons";
import {Avatar, Button, Col, Empty, Form, Image, Input, Pagination, Rate, Row, Select, Spin} from "antd";
import PlacesAutocomplete from "react-places-autocomplete";
import axiosInstance from "../../axiosInstance";
import AlertError from "../../components/Alert/error";
import ModalShowProfile from "../../components/Profile";
import {useSelector} from "react-redux";
import {USER_TYPE_CREW} from "../../constants";
import debounce from 'lodash/debounce';
import './style.scss'
import Slider from "react-slick";


function CrewsPage() {
  const {user} = useSelector(state => state.auth)
  const history = useHistory();

  const [crews, setCrews] = useState([])
  const [visibleModalShowProfile, setVisibleModalShowProfile] = useState(false)
  const [useSelected, setUserSelected] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [listServices, setListServicesConfig] = useState([])
  const [location, setLocation] = useState('');
  const [service, setService] = useState('');
  const [name, setName] = useState('');
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 20,
    totalCount: 0,
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    adaptiveHeight: true
  };

  const getListCrews = (newPage, newService, newLocation, crewName) => {
    setIsLoading(true);
    axiosInstance.post('/getCrewsListByService', {
      "body": {
        "pageNo": newPage || pagination.pageNumber,
        "perPage": pagination.pageSize,
        "serviceId": newService || service,
        "Location": newLocation || location,
        "Name": crewName || name,
      },
      "method": "getCrewsListByService"
    }).then(res => {
      const responseCrew = res.data.getCrewsListByService;
      if (responseCrew) {
        setCrews(responseCrew.data || []);
        setPagination({...pagination, totalCount: responseCrew.rowcount})
      }
      setIsLoading(false)
    }).catch(err => {
      console.log(err);
      AlertError(err.response?.message)
      setIsLoading(false)
    })
  }
  useEffect(() => {
    getListCrews();
    const initDataService = async () => {
      let results = await getListServices()
      results = results.getConfig.data;
      setListServicesConfig(results.Services)
    }
    initDataService()
  }, [])

  const renderListImages = (crew) => {
    return crew?.portfolios?.map((item, index) =>
          <Image key={item.id} src={item.Media}  alt=""  />
    )
  }

  const handleSelectLocation = async value => {
    setLocation(value);
    getListCrews("", "", value)
  };

  async function handlePageChange(newPage) {
    setPagination({
      ...pagination,
      pageNumber: newPage,
    });
    getListCrews(newPage);
  }

  const onSelectedUser = userId => {
    setUserSelected(userId)
    setVisibleModalShowProfile(true)
  }
  const closeModalShowProfile = () => {
    setVisibleModalShowProfile(false)
    setUserSelected(null)
  }
  const handleBook = (crewId, serviceId) => {
    if (user && user.UserType !== USER_TYPE_CREW) {
      history.push({pathname: '/booking', search: `?serviceId=${serviceId}&crewId=${crewId}`})
    } else {
      history.push({pathname: '/login', search: `?type=crew&serviceId=${serviceId}&crewId=${crewId}`})
    }
  }
  const renderListCrew = useMemo(() => {
    if (isLoading) {
      return <div className="show-loading">
        <Spin indicator={<LoadingOutlined style={{fontSize: 24, color: "#feb034"}} spin/>}/>
      </div>
    }
    if (crews.length) {
      return <Row className='list' gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
        {crews.map(item =>
          <Col key={item.UserId} className="wp-info-crew" xs={{span: 24}} sm={{span: 24}} md={{span: 12}}>
            <div className="info-crew">
              <div className="info">
                <div className="thumb">
                  <Avatar onClick={() => onSelectedUser(item.UserId)}
                          src={item.ProfilePic || `https://orbis-alliance.com/wp-content/themes/consultix/images/no-image-found-360x260.png`}/>
                </div>
                <div className="detail">
                  <div className="name-rate">
                    <p onClick={() => onSelectedUser(item.UserId)} className="name">{item.FirstName} {item.LastName}</p>
                    <div className="rate">
                      <Rate allowHalf disabled defaultValue={item.ratings}/>
                    </div>
                  </div>
                  <p className="name-service"><ProfileFilled/>{item.serviceName}</p>
                  <p className="location"><EnvironmentFilled/>{item.Location}</p>
                  <p className="location"><DollarCircleFilled/>${parseFloat(item.serviceMaxPrice).toFixed(2)} <span>( per/hour )</span></p>

                  {item.portfolios.length > 0 &&
                    <div className="portfolios">
                    <p className="title">
                      Portfolio
                    </p>
                    {item.portfolios.length > 2 ?
                          <Slider {...settings}>
                            {renderListImages(item)}
                          </Slider>
                          :
                          <div className='list'>
                            { renderListImages(item)}
                          </div>
                    }
                  </div>
                  }


                  <Button htmlType="submit" className="w-100" id="btn_book" onClick={() => handleBook(item.UserId, item.serviceId)}>
                    Book
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>
    }
    return <div className='show-loading empty-data'>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    </div>
  }, [crews, isLoading]);
  const handleChangeService = value => {
    setService(value);
    getListCrews("", value)
  }
  const handleSearchName = (e) => {
    setName(e.target.value);
    getListCrews("", "", "", e.target.value)
  }
  return (
    <div className='crew-page'>
      <div className="widget-head">
        <h3 className="title-page">Crew Your Next Project Instantly</h3>
      </div>
      <div className="wp-inner">
        <div className="widget">
          <div className="widget-body">
            <div className="search-wrapper">
              <div className="title">
                Find Crews All Over The World
              </div>
              <div className="input-search">
                <div className="position">
                  <Select onChange={handleChangeService} style={{width: "100%", height: "100%"}} placeholder='Select position' size="large" allowClear>
                    {listServices.map(service => (<Select.Option value={service.serviceId}>{service.serviceName}</Select.Option>))}
                  </Select>
                </div>
                <div className="location">
                  <Input onChange={debounce(handleSearchName, 500)} className='input-search-gg' suffix={
                    <UserOutlined style={{color: "white"}}/>} placeholder='Enter Crew Name'/>
                </div>
                <div className="location">
                  <PlacesAutocomplete value={location}
                                      onChange={value => {
                                        setLocation(value)
                                      }}
                                      searchOptions={{
                                        componentRestrictions: {country: ['us']},
                                      }}
                                      onSelect={handleSelectLocation}
                  >

                    {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                      <div>
                        <Input className='input-search-gg' suffix={
                          <EnvironmentFilled style={{color: "white"}}/>}  {...getInputProps({placeholder: 'Enter location'})} />
                        <div>
                          {loading ? <div>...loading</div> : null}
                          {suggestions.map(suggestion => {
                            const style = {
                              backgroundColor: suggestion.active ? '#08ffc8' : '#fff',
                              padding: '10px'
                            }

                            return (
                              <div
                                key={suggestion.placeId} {...getSuggestionItemProps(suggestion, {style})}>
                                                                  <span
                                                                    key={suggestion.placeId}>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>
            </div>
            <div className="slogan">
              <h3 className="slogan-title">ANY CREW, ANY TIME, ANYWHERE</h3>
              <span className="slogan-content">Production Crew Directory provides listings for video, film, and production staff with easy search features for local market locations, positions, & owned equipment. For specific video & film crew members <strong>CREWSNOW</strong> provides Elite status based on vetting accuracy of the listing, equipment quality, and successful shoot bookings.</span>
            </div>
            <ul className="list-crews">
              <Form.Item name='crewId'>
                {renderListCrew}
              </Form.Item>
              <div className="mt-2 flex justify-content-end">
                {pagination.totalCount ? <Pagination pagination={pagination} onPageChange={handlePageChange}/> : null}
              </div>
            </ul>
          </div>
        </div>
      </div>
      {visibleModalShowProfile && useSelected && <ModalShowProfile idUser={useSelected} visible={visibleModalShowProfile} closeModal={closeModalShowProfile}/>}
    </div>
  );
}

export default CrewsPage;
